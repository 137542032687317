import { getThemes } from "@/api/content";
import { userInfo } from "@/api/login";
import { AvailableLessons } from "@/models/availableLessons";
import { Theme } from "@/models/theme";
import { defineStore } from "pinia";
import { getAvailableThemes } from "@/api/class";

export const useStore = defineStore({
    id: "user",
    state() {
        return {
            id: null as number | null,
            name: null as string | null,
            schoolId: null as number | null,
            userType: null as number | null,
            role: null as string | null,
            jwt: null as string | null,
            themeData: null as Theme[] | null,
            availableThemes: {} as AvailableLessons,
        };
    },
    getters: {
        isTeacher(): boolean {
            return this.role == "teacher";
        },
        isStudent(): boolean {
            return this.role == "student";
        },
        isManager(): boolean {
            return this.role == "school_manager";
        },
        // isInRole(): (role: string) => boolean {
        //     return (role: string): boolean => {
        //         if (this.roles != null && this.roles.includes(role)) {
        //             return true;
        //         }
        //         return false;
        //     };
        // },
        loggedIn(): boolean {
            // return localStorage.getItem("key") != null;
            return this.id != null;
        },

    }
    ,
    actions: {
        async getAccountInfo(): Promise<void> {
            const jwt = localStorage.getItem("key");
            if (jwt == null) {
                return;
            }
            try {
                const response = await userInfo();

                if (response == null) {
                    return;
                }

                this.id = response.id;
                this.name = response.username;
                this.role = response.role.type;
                this.schoolId = response.school as unknown as number;

                if (this.role == "student") {
                    const availableThemes = await getAvailableThemes();

                    this.availableThemes = availableThemes;
                }
            }

            catch (e) {
            } finally {
                //   this.root.app.toggleLoading(false);
            }
        },
        async logout(): Promise<void> {
            try {
                this.clearUserInfo();
                this.themeData = null;
                localStorage.removeItem("key");

            } catch (e) {
                throw new Error("Logout failed");
            }
        },
        async setUserInfo(userInfo: any): Promise<void> {
            // this.id = userInfo.user.id;
            // this.name = userInfo.user.username;
            // this.schoolId = userInfo.user.school.id;
            // // this.userType = userInfo.userType;
            // this.role = userInfo.user.role.type;
            this.jwt = userInfo.jwt as string;
            localStorage.setItem("key", this.jwt);
            await this.getAccountInfo();
        },
        clearUserInfo(): void {
            this.id = null;
            this.name = null;
            this.schoolId = null;
            this.userType = null;
            this.role = null;
        },
        async getThemes(): Promise<Theme[]> {
            if (!this.themeData) {
                const response = await getThemes();
                this.themeData = response;
            }

            return this.themeData;
        },
        async getTheme(id: number): Promise<Theme> {
            return (await this.getThemes()).find(x => x.order == id)!;
        }
    },

});
