<template>
  <router-link
    v-if="isLoggedIn && isLandingPage"
    :to="{ name: 'home' }"
    class="cursor-pointer whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-baloo2 font-bold text-white bg-palette-orange hover:bg-primary"
  >
    Enter Panel
  </router-link>
  <router-link
    v-else-if="!isLoggedIn"
    to="/login"
    class="cursor-pointer whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-baloo2 font-bold text-white bg-palette-orange hover:bg-primary"
  >
    Sign in
  </router-link>
  <a
    v-else
    class="cursor-pointer whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-baloo2 font-bold text-white bg-palette-orange hover:bg-primary"
    @click="onLogout()"
  >Sign Out</a>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";

export default defineComponent({
    setup() {
        const store = useStore();
        const router = useRouter();
        const route = useRoute();

        const onLogout = async () => {
            await store.user.logout();

            router.replace({ name: "landing" });
        };

        const isLandingPage = computed(() => {
            return route.matched.some(x => x.name == "landing");
        });

        return {
            onLogout,
            isLoggedIn: computed(() => store.user.loggedIn),
            isLandingPage,
        };
    }
});
</script>

<style scoped>
</style>