<template>
  <div class="flex flex-wrap justify-center content-center">
    <div class="sm:hidden">
      <select
        id="tabs"
        name="tabs"
        class="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
      >
        <option
          v-for="(tab, i) in tabs"
          :key="tab.name"
          :selected="selectedTab == i"
        >
          {{ tab.name }}
        </option>
      </select>
    </div>
    <div class="hidden sm:block">
      <div class="border-b border-gray-200">
        <nav
          class="-mb-px flex space-x-8"
          aria-label="Tabs"
        >
          <a
            v-for="(tab, i) in tabs"
            :key="tab.name"
            class="text-lg"
            :class="[selectedTab == i ? 'border-palette-orange text-palette-orange' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm cursor-pointer']"
            :aria-current="tab.current ? 'page' : undefined"
            @click="selectTab(i)"
          >
            <component
              :is="tab.icon"
              :class="[selectedTab == i ? '' : 'text-gray-400 group-hover:text-gray-500', '-ml-0.5 mr-2 h-5 w-5']"
              aria-hidden="true"
            />
            <span>{{ tab.name }}</span>
          </a>
        </nav>
      </div>
    </div>
  </div>
  <div class="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-3">
    <ul
      class="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-4 lg:gap-x-8"
    >
      <li
        v-for="c in currentTab.content"
        :key="c.title"
      >
        <div class="space-y-4">
          <div class="aspect-w-3 aspect-h-2">
            <img
              class="object-cover shadow-lg rounded-lg"
              :src="c.image"
            >
          </div>

          <div class="space-y-2">
            <div class="text-lg leading-6 font-medium space-y-1 text-center font-baloo">
              <h3 class="font-baloo-r font-normal">
                {{ c.title }}
              </h3>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, toRefs } from "vue";

import { BookOpenIcon, CollectionIcon, SpeakerphoneIcon } from "@heroicons/vue/solid";

export default defineComponent({
    setup() {

        const data = reactive({
            selectedTab: 0,

        });

        const selectTab = (index: number) => {
            data.selectedTab = index;
        };

        const currentTab = computed(() => {
            return tabs[data.selectedTab];
        });

        const tabs = [
            { name: "Books", href: "#", icon: BookOpenIcon, current: false, content: [{ title: "TEST ASD", image: "/landing/snacks.png" }, { title: "test", image: "/landing/basket.png" }, { title: "test", image: "/landing/snacks.png" }, { title: "test", image: "/landing/basket.png" }] },
            { name: "Flash Cards", href: "#", icon: CollectionIcon, current: false, content: [{ title: "asd", image: "/landing/snacks.png" }, { title: "test", image: "/landing/basket.png" }, { title: "test", image: "/landing/snacks.png" }, { title: "test", image: "/landing/basket.png" }] },
            { name: "Audio Book", href: "#", icon: SpeakerphoneIcon, current: false, content: [{ title: "zxczx", image: "/landing/snacks.png" }, { title: "test", image: "/landing/basket.png" }, { title: "test", image: "/landing/snacks.png" }, { title: "test", image: "/landing/basket.png" }] },
            { name: "Books", href: "#", icon: BookOpenIcon, current: false, content: [{ title: "test", image: "/landing/snacks.png" }, { title: "test", image: "/landing/basket.png" }, { title: "test", image: "/landing/snacks.png" }, { title: "test", image: "/landing/basket.png" }] },
            { name: "Flash Cards", href: "#", icon: CollectionIcon, current: false, content: [{ title: "qwqe", image: "/landing/snacks.png" }, { title: "test", image: "/landing/basket.png" }, { title: "test", image: "/landing/snacks.png" }, { title: "test", image: "/landing/basket.png" }] },
        ];

        return {
            ...toRefs(data),
            selectTab,
            currentTab,
            tabs,
        };
    }
});
</script>

<style scoped>
</style>