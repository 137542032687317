import { createApp } from "vue";
import { pinia } from "./store";

import router from "./router";
import App from "./App.vue";

import "@/models/themes";

import "./assets/scss/main.scss";

const app = createApp(App)
    .use(router)
    .use(pinia);

app.mount("#app");
