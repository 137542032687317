import { createPinia, setActivePinia } from "pinia";

import { useStore as useUserStore } from "./user";
import { useStore as useAppStore } from "./app";

export const pinia = createPinia();

export function useStore() {
    return {
        app: useAppStore(),
        user: useUserStore(),
    };
}