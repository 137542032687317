<template>
  <div class="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        class="mx-auto h-36 w-auto"
        src="/landing/robo.png"
        alt="Workflow"
      >
      <h2
        class="mt-6 text-center text-3xl font-extrabold text-gray-900"
      >
        Sign in to your account
      </h2>
      <!-- <p class="mt-2 text-center text-sm text-gray-600">
                Or
                {{ ' ' }}
                <a
                    href="#"
                    class="font-medium text-indigo-600 hover:text-indigo-500"
                >start your 14-day free trial</a>
            </p>-->
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form
          class="space-y-3"
          action="#"
          @submit.prevent="onLogin"
        >
          <div>
            <label
              for="email"
              class="block text-sm font-medium text-gray-700"
            >Email address</label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <input
                id="email"
                v-model="email"
                name="email"
                type="email"
                autocomplete="email"
                class="appearance-none block w-full px-3 py-2 pr-10 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                :class="[v$.email.$error ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500' : '']"
              >
              <div
                v-if="v$.email.$error"
                class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
              >
                <ExclamationCircleIcon
                  class="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            </div>
            <p
              class="mt-2 text-sm text-red-600"
              :class="[!v$.email.$error ? 'invisible' : '']"
            >
              Please enter a valid email address.
            </p>
          </div>

          <div>
            <label
              for="password"
              class="block text-sm font-medium text-gray-700"
            >Password</label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <input
                id="password"
                v-model="password"
                name="password"
                type="password"
                autocomplete="current-password"
                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                :class="[v$.password.$error ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500' : '']"
              >
              <div
                v-if="v$.password.$error"
                class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
              >
                <ExclamationCircleIcon
                  class="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            </div>
            <p
              class="mt-2 text-sm text-red-600"
              :class="[!v$.password.$error ? 'invisible' : '']"
            >
              Please enter your password.
            </p>
          </div>
          <transition
            enter-active-class="transition-opacity duration-75"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity duration-150"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <div
              v-show="wrongCredentials"
              class="rounded-md bg-red-50 p-4 transition-all"
            >
              <div class="flex">
                <div class="flex-shrink-0">
                  <XCircleIcon
                    class="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div class="ml-3">
                  <h3
                    class="text-sm font-medium text-red-800"
                  >
                    Wrong username or password
                  </h3>
                </div>
              </div>
            </div>
          </transition>
          <div>
            <button
              type="submit"
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-primary bg-palette-orange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              :disabled="isLoading"
            >
              <svg
                v-if="isLoading"
                class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                />
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
              <LoginIcon
                v-if="!isLoading"
                class="-ml-1 mr-2 h-5 w-5 transform rotate-180"
                aria-hidden="true"
              />Sign in
            </button>
          </div>
        </form>

        <!-- <div class="mt-6">
                    <div class="relative">
                        <div class="absolute inset-0 flex items-center">
                            <div class="w-full border-t border-gray-300" />
                        </div>
                        <div class="relative flex justify-center text-sm">
                            <span class="px-2 bg-white text-gray-500">Or continue with</span>
                        </div>
                    </div>
                </div>-->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRef, toRefs } from "vue";
import { useRouter } from "vue-router";
import { useVuelidate } from "@vuelidate/core";
import { email, required, minLength } from "@vuelidate/validators";

import { useStore } from "@/store";

import { login } from "@/api/login";

import { ExclamationCircleIcon, XCircleIcon, LoginIcon } from "@heroicons/vue/solid";

export default defineComponent({
    components: {
        ExclamationCircleIcon,
        XCircleIcon,
        LoginIcon,
    },
    setup() {
        const data = reactive({
            email: "",
            password: "",
            isLoading: false,
            wrongCredentials: false,
        });

        const router = useRouter();
        const store = useStore();

        const rules = {
            email: { required, email },
            password: { required, /*minLength: minLength(6)*/ },
        };

        const v$ = useVuelidate(rules, data, { $autoDirty: true, });

        const onLogin = async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }
            data.wrongCredentials = false;
            data.isLoading = true;
            try {
                const response = await login({
                    identifier: data.email,
                    password: data.password,
                });

                await store.user.setUserInfo(response);

                router.replace({ name: "home" });
            }
            catch (e: any) {
                if (e.statusCode == 400 || e.statusCode == 401) {
                    data.wrongCredentials = true;
                }
            }
            finally {
                data.isLoading = false;
            }
        };

        return {
            ...toRefs(data),
            onLogin,
            v$,
        };
    }
});
</script>

<style scoped>
</style>