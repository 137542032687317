<template>
  <!-- <nav class="flex" aria-label="Breadcrumb">
        <ol class="bg-primary rounded-md shadow px-6 flex space-x-4 h-12">
            <li class="flex">
                <div class="flex items-center">
                    <a href="#" class="text-white hover:text-black">
                        <HomeIcon class="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                        <span class="sr-only">Home</span>
                    </a>
                </div>
            </li>
            <li v-for="p in pages" :key="p.title" class="flex">
                <div class="flex items-center">
                    <svg
                        class="flex-shrink-0 w-6 h-full text-white"
                        viewBox="0 0 24 44"
                        preserveAspectRatio="none"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                    >
                        <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                    </svg>
                    <router-link
                        v-if="p.route != null"
                        :to="p.route"
                        class="ml-4 text-md font-medium text-white hover:text-black"
                        v-slot="{ href, route, navigate, isActive, isExactActive }"
                    >{{ p.title }}</router-link>
                    <span
                        class="ml-4 text-md font-medium text-white hover:text-black cursor-default"
                        v-else
                    >{{ p.title }}</span>
                </div>
            </li>
        </ol>
    </nav>-->
  <nav
    class="flex"
    aria-label="Breadcrumb"
  >
    <ol class="flex items-center space-x-4">
      <li>
        <div>
          <router-link
            :to="{ name: 'home' }"
            class="text-gray-400 hover:text-gray-500"
          >
            <HomeIcon
              class="flex-shrink-0 h-5 w-5"
              aria-hidden="true"
            />
            <span class="sr-only">Home</span>
          </router-link>
        </div>
      </li>
      <li
        v-for="p in pages"
        :key="p.title"
      >
        <div class="flex items-center">
          <ChevronRightIcon
            class="flex-shrink-0 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <!-- <a :href="page.href" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700" :aria-current="page.current ? 'page' : undefined">{{ page.name }}</a> -->
          <router-link
            v-if="p.route != null"
            v-slot="{ href, route, navigate, isActive, isExactActive }"
            :to="p.route"
            class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
          >
            {{ p.title }}
          </router-link>
          <span
            v-else
            class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 cursor-default"
          >{{ p.title }}</span>
        </div>
      </li>
    </ol>
  </nav>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useRouter, useRoute } from "vue-router";

import { HomeIcon, ChevronRightIcon } from "@heroicons/vue/solid";

export default defineComponent({
    components: {
        HomeIcon,
        ChevronRightIcon,
    },
    setup() {

        const router = useRouter();
        const route = useRoute();

        // router.push({name: ""})
        // debugger;
        // route.meta.breadcrumb(route)[0].title;

        // const pages = [
        //     { name: 'Projects', href: '#', current: false },
        //     { name: 'Project Nero', href: '#', current: true },
        // ]

        const pages = computed(() => {
            if (route.meta.breadcrumb) {
                return (route.meta.breadcrumb as Function)(route);
            } else {
                return [];
            }
        });

        return { pages };
    }
});
</script>

<style scoped>
</style>