<template>
  <div class="md:container md:max-w-4xl">
    <SectionTitle
      class="my-8"
      title="Why Roboland?"
    />
    <div>
      <div
        v-for="item in whyRoboItems"
        class="flex mt-2"
      >
        <div class="mr-4 flex-shrink-0 self-center">
          <img
            class="h-8 w-8"
            :src="item.image"
          >
        </div>
        <p class="my-auto text-xl">
          {{ item.content }}
        </p>
      </div>
    </div>
    <div class="mt-4">
      <img
        class="m-auto rounded-lg"
        src="@/assets/landing/why_roboland_banner.jpg"
      >
    </div>
    <SectionTitle
      class="my-8"
      title="What are the benefits of Roboland?"
    />

    <div class="grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-16 mt-6">
      <div
        v-for="b in benefits"
        :key="b.content"
        class="shadow-2xl rounded-2xl bg-primary text-white overflow-hidden lg:max-w-sm"
      >
        <img
          class="m-auto rounded-lg"
          :src="b.image"
        >
        <div class="p-4">
          <h3 class="text-3xl text-center font-bold">
            {{ b.title }}
          </h3>
          <p class="text-xl text-center">
            {{ b.content }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import SectionTitle from "./SectionTitle.vue";

import WhyRoboBullet1 from "@/assets/landing/why_robo_bullet_1.png";
import WhyRoboBullet2 from "@/assets/landing/why_robo_bullet_2.png";
import WhyRoboBullet3 from "@/assets/landing/why_robo_bullet_3.png";
import WhyRoboBullet4 from "@/assets/landing/why_robo_bullet_4.png";

import Benefits1 from "@/assets/landing/benefits_1.jpg";
import Benefits2 from "@/assets/landing/benefits_2.jpg";
import Benefits3 from "@/assets/landing/benefits_3.jpg";

const whyRoboItems = [
    {
        image: WhyRoboBullet1,
        content: "Natural Language Acquisition Programme (NAP) for children."
    },
    {
        image: WhyRoboBullet2,
        content: "Constructed on sixteen stories and sixteen themes."
    },
    {
        image: WhyRoboBullet3,
        content: "Addresses all senses of students through nature from the holistic and educational experience."
    },
    {
        image: WhyRoboBullet4,
        content: "The online system makes learning available at home."
    },
];

const benefits = [{
    image: Benefits1,
    title: "Student",
    content: "You can go on a different adventure in every storybook and meet with many characters. You can have unique experience while learning English with CLIL themes."
},
{
    image: Benefits2,
    title: "Teacher",
    content: "You can enhance your teaching skills through the content-based programme and detailed lesson plans. Enjoy discovering English again with your students!"
},
{
    image: Benefits3,
    title: "Parent",
    content: "The programme is simple and user-friendly. Your children can improve language skills at home with the online system. You will see your children have fun learning English!"
}];

export default defineComponent({
    components: {
        SectionTitle,
    },
    setup() {

        return { whyRoboItems, benefits };
    }
});
</script>

<style lang="scss" scoped>
</style>