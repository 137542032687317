interface ApiError {
    code: number;
    description: string;
}

interface ApiPagination {
    total: number;
    limit: number;
    offset: number;
    returned: number;
}

export interface ApiResponse<T> {
    data: T;
    errors: ApiError[];
    pagination?: ApiPagination;
}

export const enum RequestType {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
}
