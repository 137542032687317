<template>
  <div class="justify-center select-none">
    <h1 class="font-josefin text-4xl mt-3 mb-3">
      Lesson {{ lesson }} - Songs
    </h1>
    <div class="grid grid-cols-1 md:grid-cols-1 content-center gap-y-16">
      <!-- <div v-for="">
                <audio class="m-auto" controls>
                    <source :src="getAbsolutePath(data.walkieTalkieAudio.url)" type="audio/ogg" />
                </audio>
            </div> -->
      <div
        v-for="(item, i) in data.songs"
        :key="i"
        class="text-center space-y-2 flex flex-col items-center"
      >
        <h2 class="text-2xl font-bold">
          {{ item.title }}
        </h2>
        <iframe
          class="w-full h-full aspect-video"
          width="560"
          height="315"
          :src="item.url+'?rel=0'"
          :title="item.title"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, Ref, ref, toRefs } from "vue";

import { useStore } from "@/store";
import { getAbsolutePath } from "@/api/api";
import LessonItem from "@/components/LessonItem.vue";

import { contentTypes } from "@/models/content";

export default defineComponent({
    components: {
        LessonItem,
    },
    props: {
        theme: { type: Number, required: true },
        lesson: { type: Number, required: true },
    },
    async setup(props) {
        const store = useStore();
        const data = (await store.user.getTheme(props.theme));

        return {
            ...toRefs(props),
            contentTypes,
            data,
            getAbsolutePath,
        };
    }
});

</script>

<style lang="scss">
.item-content {
    a {
        text-decoration: underline black;
        color: blue !important;
    }
}
</style>