import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { useStore } from "@/store";

export const roleGuard = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const store = useStore();
    const userRole = store.user.role;
    const loggedIn = store.user.loggedIn;

    const requiredRoles = to.matched.filter(x => x.meta?.role != null).map(x => x.meta.role);

    requiredRoles.forEach((role) => {
        if (role == "loggedIn" && loggedIn) {
            return;
        }
        else if (role == userRole) {
            return;
        }
        else {
            if (loggedIn) {
                next({ name: "home" });
            }
            else {
                next({ name: "landing" });
            }
        }
    });

    next();
};