<template>
  <div
    v-if="theme"
    class="justify-center font-baloo2 select-none mt-4"
  >
    <h1
      class="text-5xl text-center mb-12 border-dashed border-palette-orange border-4 rounded-xl p-2 font-baloo2 font-extrabold"
    >
      {{ theme.name }}
    </h1>
    <div v-if="isTeacher">
      <h2 class="text-3xl font-bold">
        Themes
      </h2>
      <div class="grid md:grid-cols-5 sm:grid-cols-1 gap-4 md:justify-center font-bold mt-4">
        <router-link
          v-for="i in 10"
          :to="{ name: 'teacher-lesson', params: { theme: theme.order, lesson: i } }"
          class="transition transform duration-300 motion-safe:hover:scale-110"
        >
          <div
            class="justify-center rounded-2xl h-16 bg-palette-orange text-white text-2xl flex content-center flex-wrap p-3"
          >
            <div>{{ weekDays[(i-1)%5] }}</div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="flex flex-wrap mt-4 justify-center font-bold">
      <component
        :is="!isContentAvailable(i) ? 'span' : 'router-link'"
        v-for="(c,i) in contentTypes"
        :to="{ name: c.route, params: { theme: theme.order } }"
        :disabled="isContentAvailable(i)"
      >
        <div
          class="h-48 w-48 my-4 mx-1 md:mx-2 lg:mx-8 transition transform duration-300 motion-safe:hover:scale-110 border-dashed border-palette-orange border-4 rounded-xl"
          :class="{ filter: !isContentAvailable(i), grayscale: !isContentAvailable(i) }"
        >
          <img
            :src="c.icon"
            class="h-32 w-32 m-auto"
          >
          <div class="m-auto text-2xl w-full text-center">
            {{ c.title }}
          </div>
        </div>
      </component>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent} from "vue";
import { RouteLocation, RouteLocationRaw } from "vue-router";

import { themesData } from "@/models/themes";
import { contentTypes } from "@/models/content";
// import { getTheme } from "@/api/content";
import { useStore } from "@/store";
import {weekDays} from "@/models/constants";

export default defineComponent({
    components: {

    },
    props: {
        theme: { type: Number, required: true },
    },
    async setup(props) {
        const store = useStore();

        const theme = await store.user.getTheme(props.theme);

        const isContentAvailable = (i: number): boolean => {
            //welcome teması (ilk tema) için sadece flashcardlar açık olacak (ve de circle time)
            if (props.theme == 1 && i != 0 && i != 7) {
                return false;
            }

            //boş projectler disabled
            if (i == 5 && theme.project == null) {
                return false;
            }

            if (store.user.role == "student") {
                return store.user.availableThemes[props.theme.toString()]?.[i];
            } else {
                return true;
            }
        };

        return {
            theme,
            contentTypes,
            isTeacher: store.user.isTeacher,
            isContentAvailable,
            weekDays,
        };
    }
});

export const getTitle = (route: RouteLocation) =>
    `${parseInt(route.params.theme as string) - 1} - ${themesData.find(x => x.order == parseInt(route.params.theme as string))?.title}`;

export const getBreadcrumb = (route: RouteLocation) =>
    ({ title: getTitle(route), route: { name: "theme-content", params: { theme: route.params.theme } } as RouteLocationRaw });

</script>

<style scoped>
</style>