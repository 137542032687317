<template>
  <div class="md:container mt-16 mb-12">
    <SectionTitle
      class="my-8"
      title="How to use Roboland?"
    />
    <!-- <h2 class="text-4xl w-full text-center mt-6 mb-4">6. How to use Roboland?</h2> -->
    <div class="grid grid-cols-1 md:grid-cols-2 px-4">
      <div>
        <img
          class="m-auto border-0 rounded-lg"
          src="@/assets/landing/how_to_use.png"
        >
      </div>
      <div>
        <p
          class="text-justify text-3xl mt-1"
        >
          After becoming a member of the Roboland family, you will see the "theme screen". Each theme section includes all the contents from the lesson plans to projects. By clicking on any theme, a content screen will welcome you.
        </p>
        <p
          class="text-justify text-3xl mt-4"
        >
          IF YOU ARE CURIOUS for more information please contact us!
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SectionTitle from "./SectionTitle.vue";

export default defineComponent({
    components: {
        SectionTitle,
    },
    setup() {

        return {};
    }
});
</script>

<style lang="scss" scoped>
</style>