import { AvailableLessons } from "@/models/availableLessons";
import { ClassModel, ClassSummaryModel } from "@/models/classModel";
import { request } from "./api";
import { RequestType } from "./apiInterface";

export async function getAvailableThemes(): Promise<AvailableLessons> {
    return request<AvailableLessons>("classes/getAvailableThemes", RequestType.GET);
}

export async function setAvailableThemes(model: AvailableLessons): Promise<void> {
    return request<void>("classes/setAvailableThemes", RequestType.POST, undefined, model);
}

export async function createClass(model: ClassModel): Promise<void> {
    return request<void>("classes", RequestType.POST, undefined, model);
}

export async function updateClass(model: ClassModel): Promise<void> {
    return request<void>(`classes/${model.id}`, RequestType.PUT, undefined, model);
}

export async function deleteClass(id: number): Promise<void> {
    return request<void>(`classes/${id}`, RequestType.DELETE);
}

export async function getClassWithDetails(id: number): Promise<ClassModel> {
    return request<ClassModel>(`classes/${id}/detail`, RequestType.GET);
}

export async function getClasses(): Promise<ClassSummaryModel[]> {
    return request<ClassSummaryModel[]>("classes", RequestType.GET);
}