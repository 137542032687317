<template>
  <div class="mb-8 p-4 border-dashed border-palette-orange border-2 rounded-xl font-baloo2">
    <div class="sm:w-full flex">
      <div class="w-16 h-16">
        <img :src="icon">
      </div>
      <div class="w-full lg:w-auto ml-2 flex justify-between">
        <div class="sm:w-auto lg:w-72 h-8 text-3xl font-bold">
          {{ title }}
        </div>
        <div class="h-8 font-ligh italic text-gray-500">
          <span v-if="duration">~{{ duration }} min</span>
        </div>
      </div>
    </div>
    <div class="text-black mt-4 prose">
      <!-- content -->
      <slot />
      <slot name="detail" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, toRefs } from "vue";

export default defineComponent({
    props: {
        title: { type: String, required: true },
        icon: { type: String, required: true },
        duration: { type: String, required: false },
    },
    setup(props) {

        return {
            ...toRefs(props),
        };
    }
});
</script>

<style scoped>
</style>