<template>
  <footer>
    <div class="max-w-7xl mx-auto py-6 px-4 overflow-hidden sm:px-6 lg:px-8">
      <nav
        class="-mx-5 -my-2 flex flex-wrap justify-center"
        aria-label="Footer"
      >
        <div class="px-5 py-2">
          <a
            href="/about"
            class="text-base text-white hover:text-gray-900"
          >About</a>
        </div>

        <div class="px-5 py-2">
          <a
            href="/for-teachers"
            class="text-base text-white hover:text-gray-900"
          >For Teachers</a>
        </div>

        <div class="px-5 py-2">
          <a
            href="/contact"
            class="text-base text-white hover:text-gray-900"
          >Contact Us</a>
        </div>
      </nav>
      <p class="mt-8 text-center text-base text-white">
        &copy; 2021 MyRoboLand
      </p>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    setup() {

        return {};
    }
});
</script>

<style scoped>
footer {
    background-color: #678f36;
}
</style>