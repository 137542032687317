<template>
  <Popover open="true" class="relative z-10">
    <div class="max-w-7xl mx-auto md:px-0 px-6">
      <div
        class="flex justify-between items-center border-b-2 border-palette-orange py-3 mb-2 md:justify-start md:space-x-10"
      >
        <div class="flex justify-start lg:w-0 lg:flex-1">
          <router-link :to="{ name: 'landing' }">
            <img src="@/assets/nav_logo.png" />
            <!-- <span class="text-3xl font-extrabold">Roboland</span> -->
            <!-- <img
              class="h-8 w-auto sm:h-10"
              src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
            />-->
          </router-link>
        </div>
        <div class="-mr-2 -my-2 md:hidden">
          <PopoverButton
            class="bg-palette-orange rounded-md p-2 inline-flex items-center justify-center text-white hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-palette-orange"
          >
            <span class="sr-only">Open menu</span>
            <MenuIcon class="h-6 w-6" aria-hidden="true" />
          </PopoverButton>
        </div>
        <nav v-if="!isLandingPage" class="hidden md:flex space-x-10">
          <router-link
            v-for="l in links"
            :key="l.title"
            :to="{ name: l.route }"
            active-class="border-palette-orange"
            class="text-base font-extrabold text-gray-500 hover:text-gray-900 border-b-2"
          >{{ l.title }}</router-link>
          <!-- <a href="#" class="text-base font-medium text-gray-500 hover:text-gray-900">Docs</a> -->
        </nav>
        <div class="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
          <LoginButton />
        </div>
      </div>
    </div>

    <transition
      enter-active-class="duration-200 ease-out"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-100 ease-in"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
        <PopoverPanel
          focus
          class="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div
            class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50"
          >
            <div class="pt-5 pb-6 px-5">
              <div class="flex items-center justify-between">
                <div>
                  <img class="h-8 w-auto" src="@/assets/nav-robo-icon.png" alt="Workflow" />
                </div>
                <div class="-mr-2">
                  <PopoverButton
                    class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-palette-orange"
                  >
                    <span class="sr-only">Close menu</span>
                    <XIcon class="h-6 w-6" aria-hidden="true" />
                  </PopoverButton>
                </div>
              </div>
              <div class="mt-6">
                <nav class="grid gap-y-8">
                  <!-- <a
                  v-for="item in solutions"
                  :key="item.name"
                  :href="item.href"
                  class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                >
                  <component
                    :is="item.icon"
                    class="flex-shrink-0 h-6 w-6 text-indigo-600"
                    aria-hidden="true"
                  />
                  <span class="ml-3 text-base font-medium text-gray-900">{{ item.name }}</span>
                  </a>-->
                  <PopoverButton
                    :as="RouterLink"
                    v-for="l in links"
                    :key="l.title"
                    :to="{ name: l.route }"
                    active-class="border-palette-orange"
                    class="text-base font-extrabold text-gray-500 hover:text-gray-900 border-b-2"
                  >
                    {{ l.title }}
                  </PopoverButton>
                </nav>
              </div>
            </div>
            <div class="py-6 px-5 space-y-6">
              <div>
                <PopoverButton :as="LoginButton" class="w-full" />
              </div>
            </div>
          </div>
        </PopoverPanel>
    </transition>
  </Popover>
</template>
  

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { RouterLink } from "vue-router";
import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/vue'
import {
  BookmarkAltIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorClickIcon,
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SupportIcon,
  ViewGridIcon,
  XIcon,
} from '@heroicons/vue/outline';
import { ChevronDownIcon } from '@heroicons/vue/solid';

import { useStore } from "@/store";
import { useRouter, useRoute } from "vue-router";
import LoginButton from "../LoginButton.vue";

const teacherLinks = [
  {
    title: "Home",
    route: "home",
  },
  {
    title: "Themes",
    route: "theme",
  },
  {
    title: "Syllabus",
    route: "teacher-syllabus",
  },
  {
    title: "Lesson Flow",
    route: "teacher-lesson-flow",
  },
  {
    title: "My Students",
    route: "teacher-students",
  },
];

const studentLinks = [
  {
    title: "Home",
    route: "home",
  },
  {
    title: "Themes",
    route: "theme",
  },
  // {
  //   title: "My Students",
  //   route: "teacher-students",
  // },
];

export default defineComponent({
  components: {
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    ChevronDownIcon,
    MenuIcon,
    XIcon,
    LoginButton,
  },
  setup() {
    const searchDropdown = ref(false);
    const isPopoverOpen = ref(false);

    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const isLandingPage = computed(() => {
      return route.matched.some(x => x.name == "landing-parent");
    });

    const showSearchDropdown = () => {
      searchDropdown.value = true;
    };

    const hideSearchDropdown = () => {
      searchDropdown.value = false;
    };

    const links = computed(() => {
      if (store.user.isTeacher) {
        return teacherLinks;
      } else if (store.user.isTeacher) {
        return studentLinks;
      } else {
        return [];
      }
    });

    router.afterEach(x => { isPopoverOpen.value = false; });

    return {
      links,
      isLandingPage,
      isPopoverOpen,
      RouterLink,
      LoginButton,
    };
  }
});
</script>
  