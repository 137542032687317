import ImageActivityBoook from "@/assets/content-activity-book.png";
import ImageFlashcards from "@/assets/content-flashcards.png";
// import ImageGames from "@/assets/content-games.png";
import ImageStory from "@/assets/content-storybook.png";
import ImageWalkieTalkie from "@/assets/content-walkie-talkie.png";
import ImageSongs from "@/assets/content-songs.png";
import ImageNewsletter from "@/assets/content-story.png";
import ImageProjects from "@/assets/content-project.png";
import ImageExtraActivities from "@/assets/content-extra-activity.png";
import ImageCircleTime from "@/assets/content-circle-time.png";

export const contentTypes = [
    {
        title: "Flashcards",
        icon: ImageFlashcards,
        route: "flashcards",
    },
    {
        title: "Activity Book",
        icon: ImageActivityBoook,
        route: "activity-book",
    },
    {
        title: "Story",
        icon: ImageStory,
        route: "story",
    },
    // {
    //     title: "Walkie Talkie",
    //     icon: ImageWalkieTalkie,
    //     route: "walkie-talkie",
    // },
    {
        title: "Songs",
        icon: ImageSongs,
        route: "songs",
    },
    {
        title: "Extra Activities",
        icon: ImageExtraActivities,
        route: "extra-activities",
    },
    {
        title: "Projects",
        icon: ImageProjects,
        route: "project",
    },
    // {
    //     title: "Games",
    //     icon: ImageGames,
    //     route: "games",
    // },
    {
        title: "Newsletter",
        icon: ImageNewsletter,
        route: "newsletter",
    },
    {
        title: "Circle Time",
        icon: ImageCircleTime,
        route: "circle-time",
    },
];