<template>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 font-baloo2">
    <top-bar2 />
    <!-- <divider/> -->
    <breadcrumb class="mt-4 mb-2" />
    <router-view v-slot="{ Component }">
      <template v-if="Component">
        <transition
          enter-active-class="transition-all transition-fastest ease-out-quad"
          leave-active-class="transition-all transition-faster ease-in-quad"
          enter-class="opacity-0 scale-70"
          enter-to-class="opacity-100 scale-100"
          leave-class="opaticy-100 scale-100"
          leave-to-class="opacity-0 scale-70"
          mode="out-in"
        >
          <suspense
            @pending="onPending"
            @resolve="onResolve"
          >
            <template #default>
              <component :is="Component" />
            </template>
            <template #fallback>
              <div>Loading...</div>
            </template>
          </suspense>
        </transition>
      </template>
    </router-view>
  </div>
</template>

<script lang="ts">
import {defineComponent, computed, onMounted, ref, watch, nextTick} from "vue";
import Breadcrumb from "./Breadcrumb.vue";
import Divider from "./Divider.vue";
import TopBar2 from "./TopBar2.vue";
import {useStore} from "@/store";

export default defineComponent({
    components: {
        Breadcrumb,
        Divider,
        TopBar2
    },
    setup() {
        const store = useStore();

        const onPending = () => {
            console.log("suspense pending");
            // store.app.loading = true;
        };

        const onResolve = () => {
            console.log("suspense resolved");
            // store.app.loading = false;
        };

        return {onPending, onResolve};
    }
});
</script>
  