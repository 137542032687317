import { getThemes } from "@/api/content";
import { userInfo } from "@/api/login";
import { AvailableLessons } from "@/models/availableLessons";
import { Theme } from "@/models/theme";
import { defineStore } from "pinia";
import { getAvailableThemes } from "@/api/class";

export const useStore = defineStore({
    id: "app",
    state() {
        return {
            loading:false,
        };
    },
});
