<template>
  <div class="bg-background-dim">
    <div class="min-h-screen mx-auto">
      <div class="relative overflow-hidden">
        <top-bar2 />
        <router-view />
        <footer-comp />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TopBar2 from "./TopBar2.vue";
import FooterComp from "./FooterComp.vue";

export default defineComponent({
    components: { TopBar2, FooterComp },
    setup() {
        return {};
    }
});
</script>

<style scoped>
</style>