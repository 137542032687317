<template>
  <div class="robo-numbers-bg p-4 my-12 shadow-xl">
    <div class="md:container">
      <h2 class="text-4xl w-full text-center mb-4 text-white">
        Roboland in numbers
      </h2>
      <div class="flex flex-wrap justify-around">
        <div
          v-for="item in items"
          :key="item.title"
          class="bg-white border-0 rounded-2xl overflow-hidden w-32 mb-3"
        >
          <img
            class="m-auto"
            :src="item.icon"
          >
          <div class="pt-0 p-4">
            <p
              class="text-xl text-center font-bold"
              v-html="item.title"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import IconActivity from "@/assets/landing/icon_activity.png";
import IconAnimation from "@/assets/landing/icon_animation.png";
import IconBook from "@/assets/landing/icon_book.png";
import IconExtra from "@/assets/landing/icon_extra.png";
import IconFlashcard from "@/assets/landing/icon_flashcard.png";
import IconLesson from "@/assets/landing/icon_lesson.png";
import IconProject from "@/assets/landing/icon_project.png";
import IconStorybook from "@/assets/landing/icon_storybook.png";

export default defineComponent({

    setup() {
        const items = [
            {
                icon: IconBook,
                title: "40 <br/> Themes",
            },
            {
                icon: IconStorybook,
                title: "40 <br/>Storybooks",
            },
            {
                icon: IconAnimation,
                title: "40 <br/>Story Animations",
            },
            {
                icon: IconActivity,
                title: "4 <br/>Activity Books",
            },
            {
                icon: IconLesson,
                title: "432 <br/> Lesson Plans",
            },
            {
                icon: IconExtra,
                title: "52 <br/> Extra Activities",
            },
            {
                icon: IconFlashcard,
                title: "340 <br/> Flashcards",
            },
            {
                icon: IconProject,
                title: "16 <br/> Projects",
            },

        ];

        return {
            items
        };
    }
});
</script>

<style lang="scss" scoped>
.robo-numbers-bg {
    background-color: #678c36;
}
</style>