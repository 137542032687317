import { LoginResult, User } from "@/models/login/LoginResult";
import { request } from "./api";
import { ApiResponse, RequestType } from "./apiInterface";

export async function login(model: Record<string, unknown>): Promise<LoginResult> {
    return request<LoginResult>("auth/local", RequestType.POST, undefined, model);
}

export async function userInfo(): Promise<User> {
    return request<User>("users/me", RequestType.GET);
}
// export async function logout(): Promise<ApiResponse<void>> {
//     return await request<void>("account/logout", RequestType.POST);
//   }