import {
    createRouter,
    createWebHashHistory,
    RouteLocation,
    RouteLocationRaw,
    RouteRecordRaw,
    START_LOCATION
} from "vue-router";

import Main from "@/components/layout/Main.vue";

import {getBreadcrumb as getThemeBreadcrumb, getTitle as getThemeTitle} from "@/views/Theme.vue";
// import {getBreadcrumb as getLessonBreadcrumb, getTitle} from "@/views/teacher/Lesson.vue";

import Songs from "@/views/teacher/Songs.vue";

import LandingSkeletonVue from "@/components/layout/LandingSkeleton.vue";
import LandingView from "@/views/Home.vue";
import LoginVue from "@/views/Login.vue";
// import ErrorPage from "../views/error-page/Main.vue";

import {useStore} from "@/store";
import {computed } from "vue";
import {roleGuard} from "./roleGuard";

export const getLessonTitle = (route: RouteLocation) =>
    `Lesson ${route.params.lesson}`;

export const getLessonBreadcrumb = (route: RouteLocation) =>
    ({ title: getLessonTitle(route), route: { name: "teacher-lesson", params: { theme: route.params.theme, lesson: route.params.lesson } } as RouteLocationRaw });

const getHomePageForUser = computed(() => {
    const store = useStore();
    if (store.user.isTeacher) {
        return import("@/views/teacher/Home.vue");
    } else if (store.user.isStudent) {
        return import("@/views/student/Home.vue");
    } else if (store.user.isManager) {
        return import("@/views/manager/Home.vue");
    }
    throw "No homepage for user found";
});

const routes: RouteRecordRaw[] = [
    {
        path: "/app",
        component: Main,
        redirect: {name: "home"},
        meta: {
            role: "loggedIn"
        },
        children: [
            {
                path: "home",
                name: "home",
                component: () => getHomePageForUser.value,
            },
            {
                path: "theme",
                name: "theme",
                component: () => import("@/views/ThemeList.vue"),
            },
            {
                path: "theme",
                name: "theme-children",
                redirect: {name: "theme"},
                children: [
                    {
                        path: ":theme",
                        name: "theme-content",
                        component: () => import("@/views/Theme.vue"),
                        props: true,
                        meta: {
                            breadcrumb: (route: RouteLocation) => [{
                                title: getThemeTitle(route),
                                route: {name: "theme", params: {theme: route.params.theme}} as RouteLocationRaw
                            }],
                        }
                    },
                    {
                        path: ":theme/lesson/:lesson",
                        name: "teacher-lesson",
                        component: () => import("@/views/teacher/Lesson.vue"),
                        props: true,
                        meta: {
                            breadcrumb: (route: RouteLocation) => [
                                getThemeBreadcrumb(route),
                                getLessonBreadcrumb(route),
                            ],
                            role: "teacher",
                        }
                    },
                    {
                        path: ":theme/lesson/:lesson/activity-book",
                        name: "lesson-activity-book",
                        component: () => import("@/views/teacher/ActivityBook.vue"),
                        props: true,
                        meta: {
                            breadcrumb: (route: RouteLocation) => generateContentBreadcrumb(route, "Activity Book"),
                        }
                    },
                    {
                        path: ":theme/lesson/:lesson/story",
                        name: "lesson-story",
                        component: () => import("@/views/teacher/Story.vue"),
                        props: true,
                        meta: {
                            breadcrumb: (route: RouteLocation) => generateContentBreadcrumb(route, "Story"),
                        }
                    },
                    {
                        path: ":theme/lesson/:lesson/songs",
                        name: "lesson-songs",
                        component: Songs,
                        props: true,
                        meta: {
                            breadcrumb: (route: RouteLocation) => generateContentBreadcrumb(route, "Songs"),
                        }
                    },
                    {
                        path: ":theme/lesson/:lesson/flashcards",
                        name: "lesson-flashcards",
                        component: () => import("@/views/teacher/Flashcards.vue"),
                        props: true,
                        meta: {
                            breadcrumb: (route: RouteLocation) => generateContentBreadcrumb(route, "Flashcards"),
                        }
                    }, {
                        path: ":theme/lesson/:lesson/circle-time",
                        name: "lesson-circle-time",
                        component: () => import("@/views/teacher/CircleTime.vue"),
                        props: true,
                        meta: {
                            breadcrumb: (route: RouteLocation) => generateContentBreadcrumb(route, "Circle Time"),
                        }
                    },
                    {
                        path: ":theme/lesson/:lesson/newsletter",
                        name: "lesson-newsletter",
                        component: () => import("@/views/teacher/Newsletter.vue"),
                        props: true,
                        meta: {
                            breadcrumb: (route: RouteLocation) => generateContentBreadcrumb(route, "Newsletter"),
                        }
                    },
                    {
                        path: ":theme/lesson/:lesson/project",
                        name: "lesson-project",
                        component: () => import("@/views/teacher/Project.vue"),
                        props: true,
                        meta: {
                            breadcrumb: (route: RouteLocation) => generateContentBreadcrumb(route, "Project"),
                        }
                    },
                    {
                        path: ":theme/lesson/:lesson/project",
                        name: "lesson-extra-activities",
                        component: () => import("@/views/teacher/ExtraActivities.vue"),
                        props: true,
                        meta: {
                            breadcrumb: (route: RouteLocation) => generateContentBreadcrumb(route, "Extra Activities"),
                        }
                    },

                    //bare content
                    {
                        path: ":theme/activity-book",
                        name: "activity-book",
                        component: () => import("@/views/teacher/ActivityBook.vue"),
                        props: true,
                        meta: {
                            breadcrumb: (route: RouteLocation) => [getThemeBreadcrumb(route), {title: "Activity Book"}],
                        }
                    },
                    {
                        path: ":theme/story",
                        name: "story",
                        component: () => import("@/views/teacher/Story.vue"),
                        props: true,
                        meta: {
                            breadcrumb: (route: RouteLocation) => [getThemeBreadcrumb(route), {title: "Story"}],
                        }
                    },
                    {
                        path: ":theme/songs",
                        name: "songs",
                        component: Songs,
                        props: true,
                        meta: {
                            breadcrumb: (route: RouteLocation) => [getThemeBreadcrumb(route), {title: "Songs"}],
                        }
                    },
                    {
                        path: ":theme/flashcards",
                        name: "flashcards",
                        component: () => import("@/views/teacher/Flashcards.vue"),
                        props: true,
                        meta: {
                            breadcrumb: (route: RouteLocation) => [getThemeBreadcrumb(route), {title: "Flashcards"}],
                        }
                    },
                    {
                        path: ":theme/circle-time",
                        name: "circle-time",
                        component: () => import("@/views/teacher/CircleTime.vue"),
                        props: true,
                        meta: {
                            breadcrumb: (route: RouteLocation) => [getThemeBreadcrumb(route), {title: "Circle Time"}],
                        }
                    },
                    {
                        path: ":theme/newsletter",
                        name: "newsletter",
                        component: () => import("@/views/teacher/Newsletter.vue"),
                        props: true,
                        meta: {
                            breadcrumb: (route: RouteLocation) => [getThemeBreadcrumb(route), {title: "Newsletter"}],
                        }
                    },
                    {
                        path: ":theme/extra-activities",
                        name: "extra-activities",
                        component: () => import("@/views/teacher/ExtraActivities.vue"),
                        props: true,
                        meta: {
                            breadcrumb: (route: RouteLocation) => [getThemeBreadcrumb(route), {title: "Extra Activities"}],
                        }
                    },
                    {
                        path: ":theme/project",
                        name: "project",
                        component: () => import("@/views/teacher/Project.vue"),
                        props: true,
                        meta: {
                            breadcrumb: (route: RouteLocation) => [getThemeBreadcrumb(route), {title: "Project"}],
                        }
                    },
                    //
                ]
            },
            {
                path: "my-students",
                name: "teacher-students",
                component: () => import("@/views/teacher/MyStudents.vue"),
                meta: {
                    role: "teacher"
                }
            },
            {
                path: "available-themes",
                name: "teacher-available-themes",
                component: () => import("@/views/teacher/AvailableThemes.vue"),
                meta: {
                    role: "teacher"
                }
            },
            {
                path: "syllabus",
                name: "teacher-syllabus",
                component: () => import("@/views/teacher/Syllabus.vue"),
                meta: {
                    role: "teacher"
                }
            },
            {
                path: "lesson-flow",
                name: "teacher-lesson-flow",
                component: () => import("@/views/teacher/LessonFlow.vue"),
                meta: {
                    role: "teacher"
                }
            },

        ],
    },
    {
        path: "/manager",
        component: () => import("@/components/layout/ManagerMain.vue"),
        redirect: {name: "manager-home"},
        meta: {
            role: "school_manager"
        },
        children: [
            {
                path: "home",
                name: "manager-home",
                component: () => import("@/views/manager/Home.vue"),
            },
            {
                path: "teacher-list",
                name: "teacher-list",
                component: () => import("@/views/manager/TeacherList.vue"),
            },
            {
                path: "student-list",
                name: "student-list",
                component: () => import("@/views/manager/StudentList.vue"),
            }, {
                path: "class-list",
                name: "class-list",
                component: () => import("@/views/manager/ClassList.vue"),
            },
        ],
    },
    {
        path: "/",
        name: "landing-parent",
        component: LandingSkeletonVue,
        redirect: {name: "landing"},
        children: [
            {
                path: "landing",
                name: "landing",
                component: LandingView
            },
            {
                path: "login",
                name: "login",
                component: LoginVue
            },
        ]
    },
    // {
    //   path: "/login",
    //   name: "login",
    //   component: Login
    // },
    {
        path: "/:pathMatch(.*)*",
        component: Main
    }
];

const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    history: createWebHashHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return savedPosition || {left: 0, top: 0};
    }
});

const generateContentBreadcrumb = (route: RouteLocation, title: string) =>
    [
        getThemeBreadcrumb(route),
        getLessonBreadcrumb(route),
        {title}
    ];

router.beforeEach(async (to, from, next) => {
    const store = useStore();

    if (typeof to.matched[to.matched.length - 1].components?.default === "function") {
        store.app.loading = true;
    }

    if (from === START_LOCATION) {
        const jwt = localStorage.getItem("key");
        if (jwt != null) {
            await store.user.getAccountInfo();
        }
    }
    next();
});

router.afterEach(async () => {
    const store = useStore();
    store.app.loading = false;
});

router.beforeEach(roleGuard);

export default router;
