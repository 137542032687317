import { Lesson } from "@/models/lesson";
import { Theme } from "@/models/theme";
import { request } from "./api";
import { RequestType } from "./apiInterface";

export async function getTheme(index: number): Promise<Theme> {
    const data = await request<Theme[]>("themes", RequestType.GET, { order: index });
    return data[0];
}

export async function getThemes(): Promise<Theme[]> {
    return request<Theme[]>("themes", RequestType.GET, { _sort: "order" });
}

export async function getDemoTheme(): Promise<Theme> {
    return request<Theme>("themes/demoContent", RequestType.GET);
}

export async function getLesson(index: number, theme: number): Promise<Lesson> {
    const data = await request<Lesson[]>("lessons", RequestType.GET, { "theme.order": theme, order: index });
    return data[0];
}