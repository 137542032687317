<template>
  <div>
    <div class="relative mb-2">
      <div class="relative">
        <!-- <div class="absolute inset-x-0 bottom-0 h-1/2 bg-primary" /> -->
        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div class="relative shadow-xl sm:rounded-2xl sm:overflow-hidden h-96">
            <div class="absolute inset-0">
              <img
                class="h-full w-full object-cover"
                src="@/assets/landing/banner_1.jpg"
                alt="Roboland banner"
              >
            </div>
            <div class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
              <h1
                class="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-7xl"
              >
                <span class="block text-black title-shadow">NAP with ROBO</span>
              </h1>
              <h2 class="text-center text-lg tracking-tight sm:text-lg lg:text-2xl">
                <span
                  class="block text-black title-shadow"
                >"Mindful Learning With Lots of Experience"</span>
              </h2>
              <div class="mt-4 max-w-sm mx-auto sm:max-w-none flex justify-center">
                <div class="space-y-0 mx-auto inline-grid sm:gap-5">
                  <!-- <a
                                        href="#"
                                        class="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-indigo-700 bg-white hover:bg-indigo-50 sm:px-8"
                                    >Get started</a>-->
                  <a href="#">
                    <img
                      class="w-32"
                      src="@/assets/landing/banner_play_button.png"
                    >
                  </a>
                </div>
              </div>
            </div>
            <img
              class="max-h-56 md:max-h-80 absolute bottom-0 left-0"
              src="@/assets/landing/banner_robo.png"
            >
          </div>
        </div>
      </div>
      <div
        class="text-center py-8 bg-background-dim bg-opacity-70 md:bg-transparent md:bg-opacity-100 relative z-10"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SectionTitle from "./SectionTitle.vue";

export default defineComponent({
    components: {
        SectionTitle,
    },
    setup() {

        return {};
    }
});
</script>

<style lang="scss" scoped>
.title-shadow {
    text-shadow: 0px 0px 10px #ffffff, 0px 0px 20px #ffffff,
        0px 0px 30px #ffffff;
}
</style>