const images = import.meta.globEager("../assets/img/themes/*.png");

export interface Theme {
    order: number;
    title: string;
    color: string;
    image: string;

}

export const themesData: Theme[] = [
    {
        order: 1,
        title: "Welcome",
        color: "#33333",
        image: images["../assets/img/themes/welcome.png"].default,
    },
    {
        order: 2,
        title: "We are back!",
        color: "#33333",
        image: images["../assets/img/themes/corn.png"].default,
    },
    {
        order: 3,
        title: "Look, what I can do!",
        color: "#33333",
        image: images["../assets/img/themes/jellyfish.png"].default,
    },
    {
        order: 4,
        title: "Stay Healthy!",
        color: "#33333",
        image: images["../assets/img/themes/grape.png"].default,
    },
    {
        order: 5,
        title: "Day Begins",
        color: "#33333",
        image: images["../assets/img/themes/bee.png"].default,
    },
    {
        order: 6,
        title: "Walking in the Nature",
        color: "#33333",
        image: images["../assets/img/themes/pear.png"].default,
    },
    {
        order: 7,
        title: "My Family",
        color: "#33333",
        image: images["../assets/img/themes/penguin.png"].default,
    },
    {
        order: 8,
        title: "Powerful Vegetables",
        color: "#33333",
        image: images["../assets/img/themes/vegetable.png"].default,
    },
    {
        order: 9,
        title: "Farm Animals",
        color: "#33333",
        image: images["../assets/img/themes/cow.png"].default,
    },
    {
        order: 10,
        title: "A Special Recipe!",
        color: "#33333",
        image: images["../assets/img/themes/pumpkin.png"].default,
    },
    {
        order: 11,
        title: "A Day In My House",
        color: "#33333",
        image: images["../assets/img/themes/kangroo.png"].default,
    },
    {
        order: 12,
        title: "My Dream Job",
        color: "#33333",
        image: images["../assets/img/themes/peanut.png"].default,
    },
    {
        order: 13,
        title: "City Life",
        color: "#33333",
        image: images["../assets/img/themes/elephant.png"].default,
    },
    {
        order: 14,
        title: "Smells Yummy!",
        color: "#33333",
        image: images["../assets/img/themes/tomato.png"].default,
    },
    {
        order: 15,
        title: "Around The World",
        color: "#33333",
        image: images["../assets/img/themes/turtle.png"].default,
    },
    {
        order: 16,
        title: "Let’s Go Shopping!",
        color: "#33333",
        image: images["../assets/img/themes/pea.png"].default,
    },
    {
        order: 17,
        title: "Packing My Bag",
        color: "#33333",
        image: images["../assets/img/themes/lion.png"].default,
    },
];