<template>
  <div v-if="images != null">
    <Dialog
      as="div"
      auto-reopen="true"
      class="fixed z-10 inset-0 overflow-y-hidden"
      :open="modelValue"
      @close="close()"
    >
      <div
        class="sm:h-screen sm:w-screen flex items-end justify-center min-h-screen pb-20 text-center sm:block p-0 md:p-4"
      >
        <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75" />
        <div
          class="inline-block align-bottom bg-white rounded-lg pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full h-screen md:h-full md:p-4"
        >
          <flipbook
            ref="flipbook"
            class="flipbook"
            :pages="images"
          />
          <div class="flex justify-center mt-4">
            <button
              type="button"
              class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-palette-orange hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-palette-orange mx-1"
              @click="flipPage(-1)"
            >
              <ChevronLeftIcon
                class="h-5 w-5"
                aria-hidden="true"
              />
            </button>
            <div
              v-if="flipbook != null"
              class="mx-4"
            >
              Sayfa {{ flipbook.page }} / {{ flipbook.numPages }}
            </div>
            <button
              type="button"
              class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-palette-orange hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-palette-orange mx-1"
              @click="flipPage(1)"
            >
              <ChevronRightIcon
                class="h-5 w-5"
                aria-hidden="true"
              />
            </button>
            <button
              type="button"
              class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-palette-orange hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-palette-orange mx-1 ml-2"
              @click="flipbook.zoomIn()"
            >
              <ZoomInIcon
                class="h-5 w-5"
                aria-hidden="true"
              />
            </button>
            <button
              type="button"
              class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-palette-orange hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-palette-orange mx-1"
              @click="flipbook.zoomOut()"
            >
              <ZoomOutIcon
                class="h-5 w-5"
                aria-hidden="true"
              />
            </button>
            <button
              type="button"
              class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-palette-orange hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-palette-orange mx-1 ml-2"
              @click="close()"
            >
              <XIcon
                class="h-5 w-5"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, Ref, ref, toRefs } from "vue";
import { Dialog, DialogOverlay, DialogTitle } from "@headlessui/vue";

import Flipbook from "@/components/flipbook/Flipbook.vue";

import {
    BookOpenIcon, ChevronLeftIcon, ChevronRightIcon, XIcon, ZoomInIcon, ZoomOutIcon,
} from "@heroicons/vue/outline";

import { getDemoTheme } from "@/api/content";
import { getAbsolutePath } from "@/api/api";
import { Theme } from "@/models/theme";

export default defineComponent({
    components: {
        BookOpenIcon,
        Flipbook,
        Dialog,
        DialogOverlay,
        DialogTitle,
        ChevronLeftIcon, ChevronRightIcon,
        XIcon,
        ZoomInIcon,
        ZoomOutIcon,
    },
    props: {
        modelValue: { required: true, type: Boolean }
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const loaded = ref(false);
        const flipbook = ref(null) as any;
        const images = ref(null) as Ref<string[] | null>;

        const loadData = async () => {
            const data = await getDemoTheme();
            images.value = data.storyBookImages.map(x => getAbsolutePath(x.url));
        };

        // const video = data.storyBookVideo ? getAbsolutePath(data.storyBookVideo.url) : null;
        const close = (): void => {
            emit("update:modelValue", false);
        };
        const flipPage = (val: number) => {
            if (val == -1) {
                flipbook.value.flipLeft();
            } else {
                flipbook.value.flipRight();
            }
        };

        loadData();

        return {
            ...toRefs(props),
            images,
            flipbook,
            ChevronLeftIcon,
            ChevronRightIcon,
            flipPage,
            close
        };
    }
});
</script>

<style lang="scss" scoped>
</style>