import axios, { AxiosError, AxiosResponse } from "axios";
import qs from "qs";

import { ApiResponse, RequestType } from "./apiInterface";

// import { UserType } from "@/models/user";

export const apiUrl = `${import.meta.env.VITE_API_URL as string}`;

const instance = axios.create({ baseURL: apiUrl });

instance.interceptors.request.use(
    async config => {
        const jwt = localStorage.getItem("key");

        if (jwt != null && config.url?.indexOf("auth/local") == -1) {
            config.headers = {
                "Authorization": `Bearer ${jwt}`,
            };
        }

        return config;
    },
    error => {
        Promise.reject(error);
    });

// const requestRefresh: TokenRefreshRequest = async (refreshToken: string): Promise<IAuthTokens | string> => {

//     return "";
// }

// applyAuthTokenInterceptor(instance, { requestRefresh });

// export function setupAxios(): void {
//     let refreshingToken = false;

// }

function logError(url: string, reason: unknown): void {
    console.error(`Error: url: ${url} \n ${reason as string}`);
}

const parseParams = (params: unknown): string => {
    if (params != null) {
        return qs.stringify(params, { allowDots: true });
    } else {
        return "";
    }
};

export async function request<T>(url: string, method: RequestType, params?: Record<string, unknown> | null,
    data?: unknown | null): Promise<T> {

    const promise = new Promise<T>((resolve, reject) => {
        const headers: Record<string, string> = {};

        // if (mainStore.user != null && mainStore.user.token != null) {
        //     headers.Authorization = `Bearer ${mainStore.user.token}`;
        // }

        // const newParams = params != null ? params : {};
        // if (mainStore.user.userType == UserType.Mbem && mainStore.user.schoolId != null) {
        //     newParams.schoolId = mainStore.user.schoolId;
        // }

        instance.request<unknown, AxiosResponse<T>>({
            url: `${apiUrl}/${url}`,
            params,
            paramsSerializer: parseParams,
            data,
            method,
            headers,
            withCredentials: true,
        })
            .then((result) => {
                return resolve(result.data);
            })
            .catch((result) => {
                // debugger;
                if (result.response != null) {
                    // fillErrorDescription(result.response.data.error);
                    logError(url, result.response.error);
                    reject(result.response.data);
                } else {
                    reject();
                }
            });
    });

    return promise;
}

export function getAbsolutePath(url: string): string {
    return `${apiUrl}${url}`;
}